import React, { useState } from 'react';
import MensajesIngles from './../lang/en-US.json';
import MensajesEspañol from './../lang/es-MX.json';
import { IntlProvider } from 'react-intl';

const AppContext = React.createContext();

const AppProvider = ({ children }) => {
    let localePorDefecto;
    let mensajesPorDefecto;
    const lang = localStorage.getItem('lang');

    if (lang === 'es-MX') {
        localePorDefecto = 'es-MX';
        mensajesPorDefecto = MensajesEspañol;
    } else if (lang === 'en-US') {
        localePorDefecto = 'en-US';
        mensajesPorDefecto = MensajesIngles;
    } else {
        localePorDefecto = 'es-MX';
        mensajesPorDefecto = MensajesIngles;
    }

    const [mensajes, establecerMensajes] = useState(mensajesPorDefecto);
    const [locale, establecerLocale] = useState(localePorDefecto);

    //booking info
    const [bookingInfo, setBookingInfo] = useState({
        arrivalDate: null,
        departureDate: null,
        adults: 0,
        kids: 0,
        clientName: " ",
        phone: 0,
        id: 0,
        email: " "
    });

    const [bookingInfoTwo, setBookingInfoTwo] = useState({
        nights: 0,
        rooms: 0
    })

    //id selected when clicked on book now

    const [id, setId] = useState("")

    const updateBookingInfo = (name, value) => {
        setBookingInfo({
            ...bookingInfo,
            [name]: value
        });
    };

    const updateBookingInfoTwo = (name, value) => {
        setBookingInfoTwo({
            ...bookingInfoTwo,
            [name]: value
        });
    };

    // const to set language

    //remain darker after clicking using useState

    const [esClicked, setEsClicked] = useState(false);
    const [enClicked, setEnClicked] = useState(false);

    const establecerLenguaje = (lenguaje) => {
        switch (lenguaje) {
            case 'es-MX':
                establecerMensajes(MensajesEspañol);
                establecerLocale('es-MX');
                setEsClicked(true);
                setEnClicked(false);
                localStorage.setItem('lang', 'es-MX');
                break;
            case 'en-US':
                establecerMensajes(MensajesIngles);
                establecerLocale('en-US');
                setEsClicked(false);
                setEnClicked(true);
                localStorage.setItem('lang', 'en-US');
                break;
            default:
                establecerMensajes(MensajesIngles);
                establecerLocale('en-US');
                localStorage.setItem('lang', 'en-US');
        }
    };

    //selected room
    const [selectedRoom, setSelectedRoom] = useState([]);


    return ( < AppContext.Provider value = {
            {
                establecerLenguaje,
                bookingInfo,
                updateBookingInfo,
                bookingInfoTwo,
                updateBookingInfoTwo,
                id,
                locale,
                setId,
                esClicked,
                enClicked,
                selectedRoom,
                setSelectedRoom
            }
        } >
        <IntlProvider locale={ locale } messages={ mensajes } >
            { children }  
        </IntlProvider> 
        </AppContext.Provider >);
};

export { AppProvider, AppContext };